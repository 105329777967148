import React from 'react'
import { createUseStyles } from 'react-jss'

function Error404 () {
  const classes = useStyles()

  return (
    <main>
      <div className={classes.container}>
        404 Can't find that
      </div>
    </main>
  )
}

const useStyles = createUseStyles({
  container: {
    display: 'block',
    padding: 20,
    fontSize: 50,
    color: 'red'
  }
})

export default Error404
